import Typography from "typography"

import moraga from "typography-theme-moraga"
// import moraga from "typography-theme-lincoln"

const styles = moraga.googleFonts[0].styles;
styles[styles.length -1] = styles[styles.length -1] + '&display=swap'

moraga.overrideThemeStyles = () => {
  return {
    'code[class*=language-]': {
      fontSize: '14px !important',
    },
    ':not(pre) > code[class*="language-"]': {
      background: '#dadada !important',
      fontSize: '14px',
      color: '#4a4a4a',
    },
    '.gatsby-highlight': {
      marginBottom: '1.56rem'
    },
    'a': {
      color: '#2D77C7'
    }
  }
}

moraga.baseFontSize = '19px'

// delete Wordpress2016.googleFonts

const typography = new Typography(moraga)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
